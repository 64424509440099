<div *ngIf="!dataLoaded">
  <app-skeleton-loader></app-skeleton-loader>
</div>

<div *ngIf="dataLoaded">
  <div *ngIf="connectorExists;  else notFoundTemplate" class="main-content flex-grow-1 marginn">
    <mat-grid-list cols="3" rowHeight="1.5:1" class="borderGrid">
      <mat-grid-tile class="title1">WattzHub</mat-grid-tile>
      <mat-grid-tile class="title1">{{'qrcode.charging_Station' |
        translate}}</mat-grid-tile>
      <mat-grid-tile>
        <div class="example-button-row">
          <button class="help" mat-flat-button (click)="redirectToEv24()">{{'qrcode.help' | translate}}</button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="1" rowHeight="3:1" class="borderGrid">
      <mat-grid-list cols="1" rowHeight="9:1">
        <div class="text-container" *ngIf="chargingStation" style="padding-top: 15px;">
          <span class="title1 title2">{{'qrcode.location' | translate}}</span>
          <span>{{ chargingStation.siteArea.name }} - {{
            chargingStation.site.address.address1 }}</span>
        </div>
      </mat-grid-list>
      <mat-grid-tile>
        <div class="text-container">
          <span class="text-item title1" id="connector-status">
            {{ 'qrcode.charging_Station' | translate }} : {{ chargingStation.id }}
          </span>
          <span class="text-item title1" id="connector-status">
            {{ 'qrcode.connector' | translate }} {{ connectorId }} :
            <span [ngStyle]="{'color': connectorStatusColor}">
              ({{ connectorStatus }})
            </span>
          </span>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="3" rowHeight="4:3" class="borderGrid">
      <mat-grid-list cols="1" rowHeight="7:1">
        <mat-grid-tile class="title1 title2">{{'qrcode.tariff' |
          translate}}</mat-grid-tile>
      </mat-grid-list>

      <div id="mainCoantiner">
        <!-- <div>
          <div class="starsec"></div>
          <div class="starthird"></div>
          <div class="starfourth"></div>
          <div class="starfifth"></div>
        </div> -->
        <div class="margin-body pricing-column-wrapper">
          <div class="row">
            <table>
              <tr *ngIf="hasFlatFee" else #notFoundFee>
                <th>{{'qrcode.FlatFee' | translate}}</th>
                <th>{{ pricingDefinitions.flatFee.price }}€</th>
              </tr>
              <tr *ngIf="!hasFlatFee" #notFoundFlatFee>
                <th> &nbsp; </th>
                <th> &nbsp; </th>
              </tr>
              <tr *ngIf="hasEnergy" else #notFoundEnergy>
                <th>{{'qrcode.Energy' | translate}}</th>
                <th>{{ pricingDefinitions.energy.price }}€/kWh</th>
              </tr>
              <tr *ngIf="!hasEnergy" #notFoundEnergy>
                <th> &nbsp; </th>
                <th> &nbsp; </th>
              </tr>
              <tr *ngIf="hasChargingTime" else #notFoundFee>
                <th>{{'qrcode.ChargingTime' | translate}}</th>
                <th>{{ pricingDefinitions.chargingTime.price }}€/hour</th>
              </tr>
              <tr *ngIf="!hasChargingTime" #notFoundChargingTime>
                <th> &nbsp; </th>
                <th> &nbsp; </th>
              </tr>
              <tr *ngIf="hasParkingTime" else #notFoundFee>
                <th>{{'qrcode.Parkingtime' | translate}}</th>
                <th>{{ pricingDefinitions.parkingTime.price }}€/hour</th>
              </tr>
              <tr *ngIf="!hasParkingTime" #notFoundParkingTime>
                <th> &nbsp; </th>
                <th> &nbsp; </th>
              </tr>
            </table>
          </div>
        </div>
      </div>

    </mat-grid-list>

    <mat-grid-list cols="1" rowHeight="3:1" class="borderGrid">
      <mat-grid-tile>
        <form [formGroup]="cardForm">
          <span class="text-item title1">Merci d'entrer une adresse email pour la
            facturation</span>
          <label class="mt-20" style="width: 100%;">
            <input type="email" autocomplete="off" formControlName="email" class="white large outlined"
              [placeholder]="'name@mail.com' | translate" />
            <div *ngIf="cardForm.controls['email'].invalid && cardForm.controls['email'].touched" class="error">
              <small *ngIf="cardForm.controls['email'].errors?.email">{{'payment.invalide'
                | translate}}</small>
            </div>
          </label>
        </form>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="2" rowHeight="5:1" class="borderGrid">
      <mat-grid-list cols="1" rowHeight="6:1">
        <mat-grid-tile>
          <div class="text-container">
            <span class="text-item title1">{{'qrcode.Paymentmethod' |
              translate}}</span>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="1" rowHeight="5:1">
        <mat-grid-tile class="margin-top">
          <div class="text-container">
            <div class="example-button-row">
              <button class="credit-card" mat-flat-button (click)="redirectToPayment()">{{'qrcode.Use_credit_card' |
                translate}}</button>
            </div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-grid-list>
  </div>

</div>



<ng-template #notFoundTemplate class="marginn main-content">
  <mat-grid-list cols="3" rowHeight="1.5:1" class="borderGrid main-content marginn">
    <mat-grid-tile class="title1">WattzHub</mat-grid-tile>
    <mat-grid-tile class="title1">{{'qrcode.charging_Station' | translate}}</mat-grid-tile>
    <mat-grid-tile>
      <div class="example-button-row">
        <button class="help" mat-flat-button (click)="redirectToEv24()">{{'qrcode.help' | translate}}</button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list cols="2" rowHeight="4:2" class="borderGrid main-content marginn">
    <mat-grid-list cols="1" rowHeight="2:1">
      <mat-grid-tile>
        <div class="text-container">
          <span class="notfound">{{'qrcode.ConnectorNotFound' | translate}}</span>
          <span class="text-item title1">{{'qrcode.call_or_message' | translate}}</span>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-grid-list>
</ng-template>