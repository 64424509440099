<div class="main-content flex-grow-1 marginn mainCoantiner">
  <!-- <div>
    <div class="starsec"></div>
    <div class="starthird"></div>
    <div class="starfourth"></div>
    <div class="starfifth"></div>
  </div> -->
  <mat-grid-list cols="3" rowHeight="1.2:1">
    <mat-grid-tile class="title1">
      <div class="example-button-row">
        <button class="help" mat-flat-button (click)="goBack()">{{'payment.back' | translate}}</button>
      </div>
    </mat-grid-tile>
    <mat-grid-tile class="title1">{{'payment.payment' | translate}}</mat-grid-tile>
    <mat-grid-tile>
      <div class="example-button-row">
        <button class="help" mat-flat-button (click)="redirectToEv24()">{{'qrcode.help' | translate}}</button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <span class="cryptee">{{'payment.cryptee' | translate}}</span>

  <mat-grid-list class="card-content">
    <mat-grid-list cols="2" rowHeight="2:2" class="credit-card">
      <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedValue">
        <mat-radio-button value="1" style="padding: 20px;">
          <span class="span">{{'payment.credit_card' | translate}}</span>
        </mat-radio-button>
      </mat-radio-group>
    </mat-grid-list>
    <mat-grid-list cols="2" rowHeight="2:2" class="credit-card-details">
      <div class="box">
        <section class="card pa-50">
          <form class="form marginn" [formGroup]="cardForm">
            <div>
              <div class="row payment-card">
                <div class="col-md-12">
                  <div class="form-group">
                    <mat-form-field>
                      <label>{{'settings.billing.payment_methods_card' | translate}}</label>
                      <span id="cardNumber">
                        <input matInput />
                      </span>
                    </mat-form-field>
                    <mat-error *ngIf="cardNumberError">
                      {{cardNumberError}}
                    </mat-error>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field>
                      <label>{{'settings.billing.payment_methods_expiring_on' | translate}}</label>
                      <span id="cardExp">
                        <input matInput>
                      </span>
                    </mat-form-field>
                    <mat-error *ngIf="expirationDateError">
                      {{expirationDateError}}
                    </mat-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field>
                      <label>
                        {{'settings.billing.payment_methods_verification_code' | translate}}
                      </label>
                      <span id="cardCvc">
                        <input matInput />
                      </span>
                    </mat-form-field>
                    <mat-error *ngIf="cvcError">
                      {{cvcError}}
                    </mat-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-checkbox formControlName="acceptConditions">
                      <span class="span">{{'settings.billing.payment_methods_check' | translate}}</span>
                    </mat-checkbox>
                    <mat-error
                      *ngIf="cardForm.controls['acceptConditions'].invalid && cardForm.controls['acceptConditions'].touched">
                      {{'payment.conditions' | translate}}
                    </mat-error>
                  </div>
                  <mat-grid-list class="center-button" cols="1" rowHeight="2:2">
                    <button class="submit" type="submit" (click)="onSubmit()">
                      {{'payment.Submit' | translate}}
                    </button>
                  </mat-grid-list>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </mat-grid-list>
  </mat-grid-list>
</div>