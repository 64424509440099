<div class="skeleton-loader">
  <mat-grid-list cols="1" rowHeight="4:1">
    <mat-grid-tile class="skeleton-title"></mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list cols="1" rowHeight="3:1">
    <mat-grid-tile class="skeleton-title"></mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list cols="1" rowHeight="3:1" >
    <mat-grid-tile class="skeleton-text"></mat-grid-tile>
    <mat-grid-tile class="skeleton-text"></mat-grid-tile>
    <mat-grid-tile class="skeleton-text"></mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list cols="1" rowHeight="3:1">
    <mat-grid-tile>
      <div class="skeleton-button"></div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
